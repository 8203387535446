import React from "react";

import * as SC from "./style";

const ReviewsComponent = () => (
  <SC.StyledWrapperWithBorder>
    <SC.BlockTitle>
      Отзывы учеников здесь<span>:</span>
    </SC.BlockTitle>
    <SC.ReviewsTelegramButtonWrapper>
      <SC.ReviewsTelegramButton
        as="a"
        href="https://t.me/granich_reviews"
        target="_blank"
      >
        <SC.StyledTelegramIcon />
      </SC.ReviewsTelegramButton>
    </SC.ReviewsTelegramButtonWrapper>

    <SC.ReviewsText>
      Телеграм-канал, в который все отзывы из чатов с учениками пересылаем
    </SC.ReviewsText>
  </SC.StyledWrapperWithBorder>
);

export default ReviewsComponent;
