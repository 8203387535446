import styled, { css } from "styled-components";

import ButtonComponent from "components/layout/button";
import { WrapperWithBorder, SectionSubTitleNew } from "components/style";

const marginBottomMobile = "4vw;";

export const Section = styled.div`
  padding-top: 6vw;
  background: white;
`;

export const StyledSectionSubTitleNew = styled(SectionSubTitleNew)`
  @media only screen and (max-width: 575px) {
    margin-bottom: 9vw;
  }
`;

export const SectionDotStyle = styled.span`
  font-size: 8vw;
  line-height: 0;
  position: relative;
  left: -0.4vw;
  color: var(--granich-red);

  @media only screen and (max-width: 575px) {
    font-size: 20vw;
  }
`;

export const StyledWrapperWithBorder = styled(WrapperWithBorder)`
  padding: 0 2vw;
  display: flex;
  align-items: center;
  height: 11vw;
  max-height: 11vw;
  margin-bottom: 2.8vw;
  z-index: 1;
  background: white;

  :last-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 575px) {
    display: block;
    height: auto;
    max-height: none;
    padding: 4.5vw 9vw;
    margin-bottom: 6vw;
  }
`;

export const CardDescription = styled.div`
  font-size: 1.49vw;
  line-height: 1.2;
  width: 26vw;
  min-width: 26vw;
  max-width: 26vw;
  color: var(--granich-black);
  margin-right: 1.5vw;

  span {
    color: var(--granich-red);
  }

  i {
    color: rgb(150, 150, 150);
    font-style: normal;
  }

  @media only screen and (max-width: 575px) {
    font-size: 4.7vw;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    letter-spacing: 0;
    line-height: 1.3;
    margin-bottom: ${marginBottomMobile};
  }
`;

export const CardTitle = styled.div`
  letter-spacing: -0.1vw;
  font-weight: 900;
  color: var(--granich-black);
  white-space: nowrap;

  ${({ $size }) => {
    switch ($size) {
      case "small":
        return css`
          font-size: 2.2vw;
          span,
          i {
            font-size: 2.2vw !important;
          }
        `;
      case "large":
        return css`
          font-size: 3.2vw;
          span,
          i {
            font-size: 3.2vw !important;
          }
        `;
      default:
        return css`
          font-size: 2.9vw;
          span,
          i {
            font-size: 2.9vw !important;
          }
        `;
    }
  }}

  i {
    color: var(--granich-black);
  }

  span {
    color: var(--granich-red);
  }

  i,
  span {
    font-size: 2.5vw;
    letter-spacing: -0.1vw;
    font-weight: 900;
    font-family: Inter;
  }

  @media only screen and (max-width: 575px) {
    ${({ $size }) => {
      switch ($size) {
        case "small":
          return css`
            font-size: 5vw;
            span,
            i {
              font-size: 5vw !important;
            }
          `;
        case "large":
          return css`
            font-size: 8.5vw;
            letter-spacing: -0.5vw;
            span,
            i {
              font-size: 8.5vw !important;
              letter-spacing: -0.5vw;
            }
          `;
        default:
          return css`
            font-size: 6.5vw;
            span,
            i {
              font-size: 6.5vw !important;
            }
          `;
      }
    }}
  }
`;

const ButtonComponentBigStyle = css`
  white-space: normal;
  padding: 1vw 1.3vw;
  line-height: 1.2;
  span {
    font-size: 1.1vw;
  }

  svg {
    width: 2vw;
    height: 2vw;
    min-width: 2vw;
  }

  @media only screen and (max-width: 575px) {
    padding: 5vw 6vw;
    display: inline-flex;
    span {
      font-size: 4.3vw;
    }

    svg {
      width: 6.5vw;
      height: 6.5vw;
      min-width: 6.5vw;
      margin-right: 2vw;
    }
  }
`;

const RegulatButtonStyle = css`
  padding: 0.7vw 0.9vw;

  @media only screen and (max-width: 575px) {
    padding: 3.2vw 3.5vw;
    svg {
      width: 4vw;
      min-width: 4vw;
      height: 4vw;
      margin-right: 2vw;
    }
  }
`;

export const StyledPresentationButtonComponent = styled(ButtonComponent)`
  ${RegulatButtonStyle}

  ${({ $isBig }) =>
    $isBig &&
    css`
      ${ButtonComponentBigStyle}

      @media only screen and (max-width: 575px) {
        span {
          max-width: 30vw;
        }
      }
    `}
`;

export const StyledBuyButtonComponent = styled(ButtonComponent)`
  ${ButtonComponentBigStyle}
`;

export const StyledWatchButtonComponent = styled(ButtonComponent)`
  ${RegulatButtonStyle}
`;

export const TagsWrapper = styled.div`
  position: absolute;
  left: -0.3vw;
  top: -1.3vw;

  @media only screen and (max-width: 575px) {
    left: -1vw;
    top: -5vw;
  }
`;

export const CardTag = styled.div`
  border: 0.25vw solid rgba(49, 117, 3, 1);
  color: rgba(49, 117, 3, 1);
  background: rgba(153, 196, 120, 1);
  border-radius: 2vw;
  padding: 0.2vw 0.5vw;
  font-size: 1.25vw;
  font-weight: 600;

  @media only screen and (max-width: 575px) {
    font-size: 4.7vw;
    border-width: 1vw;
    padding: 0.6vw 1.8vw;
    border-radius: 10vw;
    font-weight: 600;
  }
`;

export const Logos = styled.div`
  width: 31.7vw;
  min-width: 31.7vw;
  height: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 575px) {
    width: 100%;
    height: 22vw;
    min-height: 22vw;
    margin-bottom: 2vw;
    ${({ $isFullWidth }) =>
      $isFullWidth &&
      `
    height: auto;
    
    `}
  }
`;

export const PrimaryIcon = styled.div`
  height: 100%;
  margin-right: 2vw;
  padding: 1.4vw 0 1.4vw 0;

  svg {
    height: 100%;
  }

  @media only screen and (max-width: 575px) {
    margin-right: 3vw;
    padding: 1vw 0 1vw;
    ${({ $isFullWidth }) =>
      $isFullWidth &&
      `
    width: 100%;
    margin-right: 0;
    
    `}
  }
`;

export const SecondaryIconWrapper = styled.div`
  margin-right: 2vw;
  width: 100%;
  height: 100%;
  padding: 1vw 0;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 575px) {
    margin-right: 0;
  }
`;

export const SecondaryIcon = styled.div`
  height: 100%;
  max-width: 95%;
  svg {
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  justify-content: center;
  padding: 0.7vw 0;

  a,
  button {
    margin-right: 0;
    margin-bottom: 0.5vw;

    :last-child {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 575px) {
    padding: 0;
    position: relative;
    top: -1vw;
    a,
    button {
      margin-bottom: 3vw;
    }
  }
`;
