import React from "react";

import { Container, SectionTitleGaramond } from "components/style";
import ContactsComponent from "./ContactsComponent";
import ReviewsComponent from "./ReviewsComponent";
import MailComponent from "./MailComponent";

import * as SC from "./style";

const SchoolContacts = () => (
  <SC.Section>
    <Container>
      <SectionTitleGaramond>Штучки</SectionTitleGaramond>
      <ContactsComponent />
      <ReviewsComponent />
      <MailComponent />
    </Container>
  </SC.Section>
);

export default SchoolContacts;
