import React from "react";

import Button from "./style";

const ButtonComponent = React.forwardRef(
  ({ icon, children, theme, asProp, href, className, ...props }, ref) => (
    <Button
      className={className}
      theme={theme}
      as={asProp}
      href={href}
      ref={ref}
      {...props}
    >
      {icon}
      <span>{children}</span>
    </Button>
  )
);

export default ButtonComponent;
