import React, { useState, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ShakeForm from "components/common/shake-form";
import useGetCourseForm from "hooks/useGetCourseFormEncrypt";
import WarningIcon from "assets/svgs/pages/index/contacts/warning.svg";
import * as SC from "./style";
import { StyledWrapperWithBorder } from "../style";

const MailComponent = () => {
  const formEl = useRef(null);
  const submitForm = () => {
    formEl.current.submit();
  };
  const [shakeTrigger, setShakeTrigger] = useState(3);
  const { getCourseTags, getCourseButtonOnClick } = useGetCourseForm();

  // initial Validation
  function isInitialValid(props) {
    if (!props.validationSchema) return true;
    return props.validationSchema.isValidSync(props.initialValues);
  }

  return (
    <div style={{ position: "relative", zIndex: 1 }}>
      <ShakeForm pose={["shake"]} poseKey={shakeTrigger}>
        <StyledWrapperWithBorder>
          <SC.Container>
            <SC.MailingMain>
              <SC.MailingTitle>
                Получать оповещения<span>:</span>
              </SC.MailingTitle>
              <Formik
                isInitialValid={isInitialValid}
                initialValues={{
                  politikaCheckbox: true,
                  formParams: {
                    email: "",
                  },
                }}
                onSubmit={submitForm}
                validationSchema={Yup.object().shape({
                  formParams: Yup.object().shape({
                    email: Yup.string()
                      .email("Это не электропочта  >-<")
                      .required("Сперва впишите почту :)"),
                  }),
                })}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                  isValid,
                  touched,
                }) => (
                  <SC.Form
                    className="main-page-form"
                    action="https://school.granich.design/pl/lite/block-public/process-html?id=855578433"
                    method="post"
                    data-open-new-window="0"
                    ref={formEl}
                    id="ltForm4927084"
                    onSubmit={e => {
                      if (isValid) {
                        handleSubmit(e);
                      } else {
                        handleSubmit(e);
                        setShakeTrigger(shakeTrigger + 1);
                        if (errors.formParams && errors.formParams.email) {
                          values.formParams.email = "";
                        }
                      }
                    }}
                  >
                    <input type="hidden" name="formParams[setted_offer_id]" />
                    <SC.WrapperInputAndButton>
                      <SC.Input
                        id="formParams[email]"
                        type="text"
                        maxLength={60}
                        placeholder={
                          errors.formParams &&
                          errors.formParams.email &&
                          touched.formParams &&
                          touched.formParams.email
                            ? errors.formParams.email
                            : "Электропочта"
                        }
                        name="formParams[email]"
                        value={values.formParams.email}
                        onChange={handleChange}
                        errorStyle={
                          errors.formParams &&
                          errors.formParams.email &&
                          touched.formParams &&
                          touched.formParams.email
                            ? true
                            : false
                        }
                      />
                      <SC.Button
                        aria-label="Submit"
                        type="submit"
                        id="button7568304"
                        onClick={getCourseButtonOnClick}
                        disabled={isSubmitting}
                      >
                        <SC.SubmitButton />
                      </SC.Button>
                    </SC.WrapperInputAndButton>
                    {getCourseTags}
                  </SC.Form>
                )}
              </Formik>
              <SC.FormPolitikaLabel>
                Нажимая на кнопку в этой форме, я принимаю условия{" "}
                <SC.PrivacyPopupLink href="/privacy">
                  {" "}
                  политики конфиденциальности
                </SC.PrivacyPopupLink>
              </SC.FormPolitikaLabel>
            </SC.MailingMain>
            <SC.MailingInfo>
              <SC.MailingInfoText>
                Оповещения о новых видео‑уроках и полезных постах с теорией
                (если они будут). Никакого спама.
              </SC.MailingInfoText>
              <SC.MailingInfoText>
                <WarningIcon /> Оповещения о наборах на Курс публикуем в Блоге
                Школы!
              </SC.MailingInfoText>
            </SC.MailingInfo>
            <SC.StyledGranichMagic />
          </SC.Container>
        </StyledWrapperWithBorder>
      </ShakeForm>
    </div>
  );
};

export default MailComponent;
